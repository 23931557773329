<template>
  <v-list-item dense>
    <v-list-item-avatar tile>
      <planete-icon width="24" height="24">{{ category.icon }}</planete-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ $t("notifications." + category.slug + ".subtitle") }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ notification.data.date }} - {{ notification.data.agent }}<br>
        <v-icon class="mr-1" small>mdi-file-pdf-box</v-icon><span v-html="notification.data.link"></span>
        <table class="w-100">
          <td v-for="(etat, i) in notification.data.etat" :key="i">{{ etat.text }} : {{ etat.value }}</td>
        </table>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "ListContrats",
  props: {
    notification: Object,
    category: Object
  }
}
</script>

<style scoped>

</style>